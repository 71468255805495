'use client';

import Image from 'next/image';
import { Stack, Typography, Box, Button, Badge, Avatar } from '@mui/material';
import { getIsTeamOnline } from '@qb/frontend/utils/getIsTeamOnline';
import { GetAppResponse } from '@/shared/types/controllers/AppControllerTypes';
type AgentAvatarProps = {
  alt: string;
  src: string;
  isOnline: boolean;
};
const AgentAvatar = ({
  alt,
  src,
  isOnline
}: AgentAvatarProps) => <Badge color="success" overlap="circular" badgeContent=" " anchorOrigin={{
  vertical: 'bottom',
  horizontal: 'right'
}} sx={{
  '.MuiBadge-badge': {
    bgcolor: isOnline ? 'success.light' : 'grey.300',
    minWidth: 10,
    width: 10,
    height: 10
  }
}} data-sentry-element="Badge" data-sentry-component="AgentAvatar" data-sentry-source-file="NavbarAgents.tsx">
    <Avatar alt={alt} sx={{
    width: 36,
    height: 36
  }} data-sentry-element="Avatar" data-sentry-source-file="NavbarAgents.tsx">
      <Image src={src} alt={alt} fill style={{
      objectFit: 'cover',
      objectPosition: 'center'
    }} data-sentry-element="Image" data-sentry-source-file="NavbarAgents.tsx" />
    </Avatar>
  </Badge>;
type NavbarAgentsProps = {
  appDetails?: GetAppResponse;
};
export const NavbarAgents = ({
  appDetails
}: NavbarAgentsProps) => {
  if (!appDetails) {
    return null;
  }
  const isTeamOnline = getIsTeamOnline(appDetails.app);
  return <Stack direction="row" sx={{
    '&:hover .MuiButton-root': {
      opacity: 1
    }
  }} data-sentry-element="Stack" data-sentry-component="NavbarAgents" data-sentry-source-file="NavbarAgents.tsx">
      <Stack direction="row" sx={{
      gap: 1,
      mr: 3
    }} data-sentry-element="Stack" data-sentry-source-file="NavbarAgents.tsx">
        {appDetails.appDisplayAgents.map(agent => <AgentAvatar key={agent.id} isOnline={isTeamOnline} alt={`${agent.firstName} ${agent.lastName}`} src={agent.profileImageURL} />)}
      </Stack>
      <Stack direction="column" sx={{
      justifyContent: 'center',
      position: 'relative'
    }} data-sentry-element="Stack" data-sentry-source-file="NavbarAgents.tsx">
        <Typography variant="bodySmallPrimary" sx={{
        lineHeight: 1.5,
        color: 'text.primary'
      }} data-sentry-element="Typography" data-sentry-source-file="NavbarAgents.tsx">
          {isTeamOnline ? 'We are online' : 'Live support:'}
          {isTeamOnline && <Box component="span" sx={{
          bgcolor: 'success.light',
          width: 10,
          height: 10,
          borderRadius: 10,
          display: 'inline-flex',
          ml: 1
        }} />}
        </Typography>
        <Typography variant="bodySmall" sx={{
        lineHeight: 1.5,
        color: 'text.primary'
      }} data-sentry-element="Typography" data-sentry-source-file="NavbarAgents.tsx">
          Monday - Friday 6AM - 6PM PST
        </Typography>
        <Button onClick={() => window?.salesPilotPostMessages?.openChatPage()} variant="contained" sx={{
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        height: 36,
        transition: theme => theme.transitions.create('opacity')
      }} data-sentry-element="Button" data-sentry-source-file="NavbarAgents.tsx">
          Chat now
        </Button>
      </Stack>
    </Stack>;
};