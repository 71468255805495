'use client';

import { memo } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Stack, IconButton, IconButtonProps } from '@mui/material';
import { Rotate } from '@qb/frontend/components/Rotate';
type Props = {
  isOpen: boolean;
  onClick: IconButtonProps['onClick'];
};
const SidebarToggleIconInternal = ({
  isOpen,
  onClick
}: Props) => <Stack data-cy="SidebarToggleIcon" sx={{
  alignItems: 'end'
}} data-sentry-element="Stack" data-sentry-component="SidebarToggleIconInternal" data-sentry-source-file="SidebarToggleIcon.tsx">
    <IconButton onClick={onClick} aria-expanded={isOpen} aria-label="Exapand or collapse sidebar" color="primary" sx={{
    borderRadius: 0,
    width: 20,
    height: 26,
    bgcolor: 'primary.main',
    color: 'common.white',
    '&:hover, &:focus': {
      bgcolor: 'primary.main',
      color: 'common.white'
    }
  }} data-sentry-element="IconButton" data-sentry-source-file="SidebarToggleIcon.tsx">
      <Rotate isRotated={isOpen} data-sentry-element="Rotate" data-sentry-source-file="SidebarToggleIcon.tsx">
        <ChevronRightIcon fontSize="small" data-sentry-element="ChevronRightIcon" data-sentry-source-file="SidebarToggleIcon.tsx" />
      </Rotate>
    </IconButton>
  </Stack>;
export const SidebarToggleIcon = memo(SidebarToggleIconInternal);