import { forwardRef, RefObject } from 'react';
import { useKey } from 'react-use';
import dynamic from 'next/dynamic';
import { Box, BoxProps, Fade, Popper } from '@mui/material';
import { NoQuery } from '@/src/components/OmniSearch/components/OmniSearchDesktopPopup/components/NoQuery';
import { Query } from '@/src/components/OmniSearch/components/OmniSearchDesktopPopup/components/Query';
export const OmniSearchBackdrop = dynamic(() => import('../OmniSearchBackdrop').then(mod => mod.OmniSearchBackdrop), {
  ssr: false
});
type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  query: string;
  isUserTyping: boolean;
  marginTop?: BoxProps['marginTop'];
};
export const OmniSearchDesktopPopup = forwardRef<HTMLElement, Props>(function Popup({
  query,
  isOpen,
  setIsOpen,
  marginTop,
  isUserTyping
}, ref) {
  const currentRef = (ref as RefObject<HTMLElement>);
  useKey('Escape', () => {
    setIsOpen(false);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  });
  return <>
        <OmniSearchBackdrop isOpen={isOpen} setIsOpen={setIsOpen} />
        <Popper open={isOpen} anchorEl={currentRef?.current} transition placement="bottom-start" sx={{
      zIndex: theme => theme.zIndex.appBar + 1,
      minWidth: 700,
      width: currentRef?.current?.offsetWidth,
      mt: 1
    }} modifiers={[{
      name: 'flip',
      enabled: false
    }]}>
          {({
        TransitionProps
      }) => <Fade {...TransitionProps}>
              <Box sx={{
          height: '100%',
          mt: marginTop
        }}>
                {query ? <Query query={query} isUserTyping={isUserTyping} /> : <NoQuery />}
              </Box>
            </Fade>}
        </Popper>
      </>;
});