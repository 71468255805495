import CreditScoreIcon from '@mui/icons-material/CreditScore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { Box, Container, Stack, Typography } from '@mui/material';
import { GetAppResponse } from '@/shared/types/controllers/AppControllerTypes';
import { NavbarAgents } from '@/src/components/Layouts/AppLayout/Navbar/components/NavbarAgents';
const links = [{
  Icon: LocalShippingIcon,
  title: 'Fast shipping'
}, {
  Icon: CreditScoreIcon,
  title: 'Secure payments'
}, {
  Icon: VerifiedUserOutlinedIcon,
  title: 'Warranty'
}];
type Props = {
  appDetails?: GetAppResponse;
};
export const NavbarLiveSupportBanner = ({
  appDetails
}: Props) => <Box sx={{
  bgcolor: 'primary.4p',
  py: 1,
  display: {
    xs: 'none',
    lg: 'block'
  },
  zIndex: 'drawer'
}} data-sentry-element="Box" data-sentry-component="NavbarLiveSupportBanner" data-sentry-source-file="NavbarLiveSupportBanner.tsx">
    <Container maxWidth={false} data-sentry-element="Container" data-sentry-source-file="NavbarLiveSupportBanner.tsx">
      <Stack direction="row" sx={{
      justifyContent: 'space-between'
    }} data-sentry-element="Stack" data-sentry-source-file="NavbarLiveSupportBanner.tsx">
        <Stack direction="row" spacing={5} data-sentry-element="Stack" data-sentry-source-file="NavbarLiveSupportBanner.tsx">
          {links.map(({
          title,
          Icon
        }) => <Stack key={title} direction="row" sx={{
          alignItems: 'center'
        }}>
              <Box component="span" sx={{
            mr: 1,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            bgcolor: 'primary.main',
            borderRadius: '50%'
          }}>
                <Icon sx={{
              color: 'common.white',
              fontSize: '14px'
            }} />
              </Box>
              <Typography variant="bodySmallPrimary" sx={{
            color: 'text.primary'
          }}>
                {title}
              </Typography>
            </Stack>)}
        </Stack>
        <NavbarAgents appDetails={appDetails} data-sentry-element="NavbarAgents" data-sentry-source-file="NavbarLiveSupportBanner.tsx" />
      </Stack>
    </Container>
  </Box>;