import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography, Stack } from '@mui/material';
export const QuickQuote = () => {
  return <Stack direction="row" spacing={0.5} sx={{
    alignItems: 'center',
    justifyContent: 'end'
  }} data-sentry-element="Stack" data-sentry-component="QuickQuote" data-sentry-source-file="QuickQuote.tsx">
      <CheckCircleIcon sx={{
      fontSize: '14px'
    }} color="info" data-sentry-element="CheckCircleIcon" data-sentry-source-file="QuickQuote.tsx" />
      <Typography variant="bodyMediumPrimary" align="center" sx={{
      color: 'text.primary'
    }} data-sentry-element="Typography" data-sentry-source-file="QuickQuote.tsx">
        Quick Quote
      </Typography>
    </Stack>;
};