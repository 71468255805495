import Image from 'next/image';
import { Stack, Typography, Chip } from '@mui/material';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { getFirstImage } from '@qb/frontend/utils/getFirstImage';
import { PartRelevantPartsResponse } from '@/shared/types/controllers/PartControllerTypes';
import { QuickQuote } from '@/src/components/Common/QuickQuote';
import { StockFound } from '@/src/components/Common/StockFound';
type PartCardHorizontalInnerProps = {
  part: PartRelevantPartsResponse['relevantParts'][0];
};
export const PartCardHorizontalInner = ({
  part
}: PartCardHorizontalInnerProps) => {
  const discountPercentage = getDiscountPercentage(part.originalPrice, part.price);
  const isDiscounted = !!discountPercentage;
  const combinedStock = part.onHandQty + part.manufacturerStock;
  return <Stack direction="row" spacing={2} sx={{
    alignItems: 'center',
    py: 1,
    px: 1.5,
    minWidth: 200,
    width: '100%'
  }} data-sentry-element="Stack" data-sentry-component="PartCardHorizontalInner" data-sentry-source-file="PartCardHorizontalInner.tsx">
      <Image src={getFirstImage(part.images)} width={50} height={50} alt={part.partNumber} style={{
      objectFit: 'contain'
    }} data-sentry-element="Image" data-sentry-source-file="PartCardHorizontalInner.tsx" />
      <Stack direction="column" sx={{
      width: '100%',
      overflow: 'hidden'
    }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
        <Stack direction="row" spacing={1} sx={{
        justifyContent: 'space-between',
        mb: 1
      }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
          <Typography variant="h6" sx={{
          mr: 'auto'
        }} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {part.partNumber}
          </Typography>
          <Stack direction="row" sx={{
          gap: 1
        }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {isDiscounted && <Chip color="accent" size="small" sx={{
            height: 19
          }} label={`${discountPercentage}% off`} />}
            {!!part.price && <Typography variant="h6" color="primary">
                {formatCents(part.price)}
                {isDiscounted && !!part.originalPrice && <>
                    <br />
                    <Typography variant="bodySmall" sx={{
                fontWeight: 600,
                color: 'text.secondary',
                textDecoration: 'line-through'
              }}>
                      {formatCents(part.originalPrice)}
                    </Typography>
                  </>}
              </Typography>}
          </Stack>
        </Stack>
        <Typography title={part.description} variant="bodyMedium" sx={[{
        mb: 1
      }, clampLinesSx(1)]} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
          {part.description}
        </Typography>
        <Stack direction="row" sx={{
        mt: 'auto',
        justifyContent: 'space-between'
      }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
          <Typography variant="bodyMediumPrimary" noWrap sx={{
          color: 'primary.light'
        }} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {part.manufacturer.name}
          </Typography>
          {combinedStock ? <StockFound stock={combinedStock} showFastShipping={false} /> : <QuickQuote />}
        </Stack>
      </Stack>
    </Stack>;
};