export const RETURN_POLICY_PAGE_URL =
  'https://app.termly.io/document/refund-policy/f7faee24-9763-401b-a1c0-f6868663b645';
export const PRIVACY_POLICY_PAGE_URL =
  'https://app.termly.io/document/privacy-policy/1a84546f-faa4-434b-b86a-a0c0132b5407';
export const COOKIE_POLICY_PAGE_URL =
  'https://app.termly.io/document/cookie-policy/03fc1ad9-9b8e-466d-888c-1a50f2818d9e';
export const TERMS_AND_CONDITIONS_POLICY_PAGE_URL =
  'https://app.termly.io/document/terms-of-service/6df8e965-8511-4e6a-a6b1-e3715b20d8b7';
export const DISCLAIMER_POLICY_PAGE_URL =
  'https://app.termly.io/document/disclaimer/dda53f8b-efdb-412c-8d80-6ee2727994cc';

export const footerNavData = [
  {
    sectionName: 'Why Quotebeam',
    elems: [
      {
        href: '/',
        text: 'Home',
      },
      {
        href: '/blog',
        text: 'Blog',
      },
      {
        href: 'https://testimonial.to/quotebeam/all',
        text: 'Customer Reviews',
        target: '_blank',
      },
    ],
  },
  {
    sectionName: 'Resources',
    elems: [
      {
        href: '/about-us',
        text: 'About Quotebeam',
      },
      {
        href: '/info/faq',
        text: 'Help Center',
      },
      {
        href: TERMS_AND_CONDITIONS_POLICY_PAGE_URL,
        text: 'Terms of Service',
        target: '_blank',
      },
      {
        href: RETURN_POLICY_PAGE_URL,
        text: 'Refund Policy',
        target: '_blank',
      },
      {
        href: PRIVACY_POLICY_PAGE_URL,
        text: 'Privacy',
        target: '_blank',
      },
      {
        href: COOKIE_POLICY_PAGE_URL,
        text: 'Cookie Policy',
        target: '_blank',
      },
      {
        href: DISCLAIMER_POLICY_PAGE_URL,
        text: 'Disclaimer',
        target: '_blank',
      },
    ],
  },
];
